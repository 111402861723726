import React from "react"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Container from "@components/container"
import PrimaryCta from "@components/PrimaryCta"

import logo from "@images/logos/xywav-logo.svg"

const NotFoundPage = () => {
  const location = { pathname: "/404" }

  return (
    <Layout
      location={location}
      headerType="agnostic"
      footerType="agnostic"
      copyright={
        <>
          &copy; 2023 Jazz Pharmaceuticals plc or its subsidiaries{" "}
          <nobr>US-XYW-2200416 Rev1123</nobr>
        </>
      }
    >
      <Seo
        title="Page not found | XYWAV®"
        meta={[{ name: "robots", content: "noindex" }]}
        location={location}
      />
      <section
        tw="relative bg-seashell py-20 overflow-hidden xl:(pt-32 pb-40)"
        className="error-404"
      >
        <Container>
          <img
            src={logo}
            tw="hidden lg:(absolute block w-[697px] -top-20 -right-[180px])"
            alt=""
          />
          <h1
            css={[
              tw`font-cocon font-bold text-[32px] leading-[1] mb-6 xl:(text-6xl mb-8)`,
            ]}
          >
            Page not
            <br tw="md:hidden" /> found
          </h1>
          <div tw="text-xl mb-7 xl:mb-14">
            Sorry, the page you requested was not found.
          </div>
          <div tw="text-center lg:text-left">
            <PrimaryCta url="/" mdSmall>
              Back to home
            </PrimaryCta>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default NotFoundPage
